import { Anchor, Flex, Menu, Modal, Text, Toast, Tooltip } from '@byecode/ui'
import type { ApplicationSettingDomain, FieldInputADTValue } from '@lighthouse/core'
import { CopyButton, domainReg, domainReg1, domainReg2, spaceVersionEnum, TagIcon, useAtomAction, useAtomData } from '@lighthouse/shared'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useAsync } from 'react-use'
import styled from 'styled-components'
import { debounce } from 'throttle-debounce'

import { updateAppAtom } from '@/atoms/application/action'
import { websiteApplicationSettingAtom } from '@/atoms/application/state'
import { openSpaceGradeConfirm, SpaceGradeTag } from '@/components/SpaceGrade'
import { WechatIntegratedCard } from '@/components/WechatIntegratedCard'
import { useAppMainDomain } from '@/hooks/useApplication'
import * as srv from '@/services'
import { useSpaceQuota } from '@/shared/reusable'

import { SettingCard } from '../SettingCard'
import { SettingItem } from '../SettingItem'
import * as CM from '../styles'
import DomainInput from './DomainInput'

interface SettingDomainProps {}

const SCxContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
`

const SCxDescribe = styled.div`
    font-size: var(--font-size-normal);
    color: var(--color-gray-400);
    line-height: 22px;
`

const SCxIconWrapper = styled(Flex)``

const SettingDomain: React.FunctionComponent<SettingDomainProps> = props => {
    const domain = useAtomData(
        websiteApplicationSettingAtom,
        useCallback(s => s?.domain, [])
    )
    const { run: updateAppSetting } = useAtomAction(updateAppAtom)
    const mainDomain = useAppMainDomain()
    const { data: quota } = useSpaceQuota()
    const [prefixEdit, setPrefixEdit] = useState(false)
    const [customEdit, setCustomEdit] = useState(false)
    const [opened, setOpened] = useState(false)

    const methods = useForm<ApplicationSettingDomain>({
        mode: 'onChange',
        defaultValues: domain
    })

    const {
        register,
        watch,
        setValue,
        control,
        formState: { errors }
    } = methods

    const [customHost = '', prefix, isUsedCustomDomain] = watch(['customDomain', 'domainPrefix', 'isUsedCustomDomain'])

    const baseHost = useMemo(() => `${prefix}.${mainDomain}`, [mainDomain, prefix])

    const { value: isSuccessBind } = useAsync(() => {
        if (!customHost) {
            return Promise.resolve(true)
        }
        return srv.getDomainBindingStatus({ domain: customHost })
    }, [customHost])

    const handleSubmit = useMemo(
        () =>
            debounce(500, async (value: ApplicationSettingDomain) => {
                const isSuccess = await updateAppSetting({
                    config: {
                        domain: value
                    }
                })
            }),
        [updateAppSetting]
    )

    const checkDomainStartAndEnd = useCallback((val: FieldInputADTValue) => {
        if (typeof val.value !== 'string') {
            return false
        }
        if (!domainReg1.test(val.value)) {
            return '不能以连字符开头或结尾'
        }
        return true
    }, [])

    const checkDomainChars = useCallback((val: FieldInputADTValue) => {
        if (typeof val.value !== 'string') {
            return false
        }
        if (!domainReg2.test(val.value)) {
            return '使用英文字母（a-z）、数字（0-9）和连字符（-）'
        }
        return true
    }, [])

    const checkDomainFormat = useCallback((val: FieldInputADTValue) => {
        if (typeof val.value !== 'string') {
            return false
        }
        if (!domainReg.test(val.value) || [...val.value].filter(v => v === '.').length > 2) {
            return '域名格式不正确提示：请输入有效的域名，例如：example.com 或 xxx.example.com'
        }
        return true
    }, [])

    useEffect(() => {
        const { unsubscribe } = watch((value, { name, type }) => {
            handleSubmit(value as ApplicationSettingDomain)
        })
        return unsubscribe
    }, [handleSubmit, updateAppSetting, watch])

    return (
        <SCxContainer>
            <FormProvider {...methods}>
                <SettingCard title="基础域名">
                    <Flex direction="column" gap={8}>
                        <Text size={12} lineHeight="30px" color="var(--color-gray-400)" style={{ marginTop: 16 }}>
                            ByeCode 免费提供的域名，统一后缀：.byecode.site
                        </Text>
                        <Controller
                            control={control}
                            name="domainPrefix"
                            render={({ field }) => (
                                <DomainInput
                                    domain={baseHost}
                                    showEdit={prefixEdit}
                                    leftSection={<CM.Icon type="ActiveNode-Website" />}
                                    validateInputProps={{
                                        defaultValue: field.value,
                                        rules: {
                                            required: {
                                                label: '不能为空',
                                                value: true
                                            },
                                            maxFontNumber: {
                                                value: 32,
                                                label: '最长长度为32个字符'
                                            },
                                            minFontNumber: {
                                                value: 5,
                                                label: '最短长度为5个字符'
                                            },
                                            validate: {
                                                checkDomainStartAndEnd,
                                                checkDomainChars
                                            }
                                        },
                                        suffix: <span style={{ color: 'var(--color-black)', paddingRight: 6 }}>.{mainDomain}</span>
                                    }}
                                    rightSection={
                                        <SCxIconWrapper gap={8}>
                                            <CM.Icon
                                                type="edit"
                                                onClick={() => {
                                                    setCustomEdit(true)
                                                    setPrefixEdit(true)
                                                }}
                                            />
                                            <Tooltip title="复制">
                                                <CopyButton value={`https://${baseHost}`} onSuccess={() => Toast.success('复制成功')} />
                                            </Tooltip>
                                        </SCxIconWrapper>
                                    }
                                    onSubmit={async v => {
                                        const res = await Modal.confirm({
                                            title: '确认修改',
                                            okText: '确定',
                                            okStatus: 'primary',
                                            content: `当前域名: ${baseHost} 将无法访问应用`
                                        })
                                        if (res) {
                                            field.onChange(v)
                                            setPrefixEdit(false)
                                        }
                                    }}
                                    onCancel={() => setPrefixEdit(false)}
                                />
                            )}
                        />
                    </Flex>
                </SettingCard>
                <Controller
                    control={control}
                    name="isUsedCustomDomain"
                    render={({ field: isUsedCustomDomainField }) => (
                        <SettingCard
                            title={
                                <Flex gap={8} alignItems="center">
                                    <Text>自定义域名</Text>
                                    <SpaceGradeTag value={spaceVersionEnum.BASIC} />
                                </Flex>
                            }
                            describe="添加后，用户可同时通过基础域名和自有域名访问应用"
                            onChangeCollapse={v => {
                                if (quota && quota?.currentVersionCode < spaceVersionEnum.BASIC) {
                                    openSpaceGradeConfirm('BASIC')
                                    return
                                }
                                isUsedCustomDomainField.onChange(v)
                                !customHost && v && setCustomEdit(true)
                            }}
                            opened={isUsedCustomDomainField.value}
                        >
                            <SettingItem label="域名地址">
                                <Flex direction="column" gap={4} style={{ marginTop: 4 }}>
                                    <SCxDescribe>
                                        请登录域名注册商或DNS服务商的控制台,管理您的域名DNS设置。不同服务商有各自的DNS设置更新方式,如果您不熟悉,请查阅服务商帮助文档。您也可以参考我们的
                                        <Anchor
                                            target="_blank"
                                            to="https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/gm1cuefzakbqylty?singleDoc#"
                                        >
                                            帮助手册{'>>'}
                                            域名设置
                                        </Anchor>
                                        。
                                    </SCxDescribe>
                                    <Controller
                                        control={control}
                                        name="customDomain"
                                        render={({ field }) => (
                                            <DomainInput
                                                domain={customHost}
                                                showEdit={customEdit}
                                                leftSection={
                                                    isSuccessBind ? (
                                                        <CM.Icon type="ActiveNode-Website" />
                                                    ) : (
                                                        <Tooltip title="连接异常，请前往域名服务商检查">
                                                            <CM.Icon type="WarningCircle" color="var(--color-red-500)" />
                                                        </Tooltip>
                                                    )
                                                }
                                                validateInputProps={{
                                                    // onCheck: srv.checkDomain,
                                                    placeholder: '例如：byecode.com',
                                                    defaultValue: field.value,
                                                    rules: {
                                                        required: {
                                                            label: '不能为空',
                                                            value: true
                                                        },
                                                        validate: {
                                                            checkDomainFormat
                                                        }
                                                    }
                                                }}
                                                onSubmit={async v => {
                                                    const res = await Modal.confirm({
                                                        title: '确认修改',
                                                        okText: '确定',
                                                        okStatus: 'primary',
                                                        content: `当前自有域名: ${customHost} 将无法访问应用`
                                                    })
                                                    if (res) {
                                                        field.onChange(v)
                                                        setCustomEdit(false)
                                                    }
                                                }}
                                                onCancel={() => {
                                                    setCustomEdit(false)
                                                    // isUsedCustomDomainField.onChange(false)
                                                }}
                                                rightSection={
                                                    <SCxIconWrapper gap={8}>
                                                        {!field.value && <CM.Icon type="edit" onClick={() => setCustomEdit(true)} />}
                                                        <Tooltip title="复制">
                                                            <CopyButton
                                                                value={`https://${customHost}`}
                                                                onSuccess={() => Toast.success('复制成功')}
                                                            />
                                                        </Tooltip>
                                                        {field.value && (
                                                            <Menu opened={opened} position="bottom-end" onChange={setOpened} width={109}>
                                                                <Menu.Target>
                                                                    <TagIcon
                                                                        icon="DotsThreeVertical"
                                                                        enableHover
                                                                        size={24}
                                                                        isActive={opened}
                                                                        iconSize={16}
                                                                    />
                                                                </Menu.Target>
                                                                <Menu.Dropdown>
                                                                    <Menu.Item
                                                                        icon={<CM.Icon type="edit" />}
                                                                        onClick={() => {
                                                                            setCustomEdit(true)
                                                                        }}
                                                                    >
                                                                        <Text>编辑</Text>
                                                                    </Menu.Item>
                                                                    <Menu.Item
                                                                        icon={<CM.Icon type="Trash" />}
                                                                        onClick={async () => {
                                                                            const res = await Modal.confirm({
                                                                                okStatus: 'error',
                                                                                title: '确认移除',
                                                                                content: `当前自有域名: ${customHost} 将无法访问应用`
                                                                            })
                                                                            if (res) {
                                                                                field.onChange('')
                                                                                setCustomEdit(false)
                                                                                isUsedCustomDomainField.onChange(false)
                                                                            }
                                                                        }}
                                                                    >
                                                                        <Text>移除</Text>
                                                                    </Menu.Item>
                                                                </Menu.Dropdown>
                                                            </Menu>
                                                        )}
                                                    </SCxIconWrapper>
                                                }
                                            />
                                        )}
                                    />
                                </Flex>
                            </SettingItem>
                            <SettingItem label="使用自有域名分享微信">
                                <Flex direction="column" gap={4}>
                                    <SCxDescribe>
                                        根据微信公众平台的要求，分享自有域名需要集成公众号，否则分享到微信的链接标签将无法体现页面信息。
                                    </SCxDescribe>
                                    <WechatIntegratedCard type="WECHAT_OFFICIAL_ACCOUNT" />
                                </Flex>
                            </SettingItem>
                        </SettingCard>
                    )}
                />
            </FormProvider>
        </SCxContainer>
    )
}

export default SettingDomain
