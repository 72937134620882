import { getAssetUrl } from '@lighthouse/assets'
import { NoFound, useAtomAction, useAtomData } from '@lighthouse/shared'
import React, { useCallback, useEffect } from 'react'

import { createPageAtom, openPageStackAtom } from '@/atoms/page/action'
import { initPageStackAtom, lastPageTypeOfStackAtom, pageStackAtom } from '@/atoms/page/state'
import { stackFactory } from '@/atoms/page/utils'
import { Page as PageContainer } from '@/containers/page'
import { useCurrentEnvId, useLanguage } from '@/hooks/useApplication'
import { useDefaultPageList } from '@/hooks/usePage'

const Page: React.FC = () => {
    const pageList = useDefaultPageList()
    const lastPageTypeOfStack = useAtomData(lastPageTypeOfStackAtom)
    const language = useLanguage()
    const envId = useCurrentEnvId()
    const { run: setPageStack } = useAtomAction(pageStackAtom)
    const { run: openPageStack } = useAtomAction(openPageStackAtom)
    const { run: initPageStack } = useAtomAction(initPageStackAtom)

    const { rootPageId, pageId, stackId, appId = '', dsId, recordId } = lastPageTypeOfStack || {}

    // 如果是初次进入，设置初始页面栈
    useEffect(() => {
        if (!pageId) {
            initPageStack()
        }
    }, [initPageStack, pageId])

    useEffect(() => {
        return () => {
            setPageStack([])
        }
    }, [setPageStack])

    const { run: createPage } = useAtomAction(createPageAtom)

    const handlePageAdd = useCallback(async () => {
        const pageId = await createPage({ name: '新的页面', lang: language })
        openPageStack(stackFactory({ appId, pageId }))
    }, [appId, createPage, language, openPageStack])

    if (pageList.length === 0) {
        return <NoFound image={getAssetUrl('empty', 'no_page.svg')} title='找不到页面' desc="没有页面" btnText="创建页面" onBtnClick={handlePageAdd} />
    }

    if (!pageId || !stackId) {
        return null
    }

    return <PageContainer rootPageId={rootPageId} pageId={pageId} appId={appId} envId={envId} dsId={dsId} recordId={recordId} stackId={stackId} />
}

export default Page
