import { Button, Flex, IconFont, Modal, Popover, Switch } from '@byecode/ui'
import React, { useState } from 'react'

import { Unit } from '../Mics'

export const Basic: React.FC = () => {
    const [open, setOpen] = useState(false)
    const [withOverlay, setWithOverlay] = useState(true)
    const [withCloseIcon, setWithCloseIcon] = useState(true)
    const [closeOnClickOverlay, setCloseOnClickOverlay] = useState(true)

    return (
        <Unit title="基础">
            <Flex direction="column" alignItems="flex-start" wrap="wrap" gap="12px">
                <Flex alignItems="center" gap="10px">
                    <div>closeOnClickOverlay: 点击遮罩时关闭</div>
                    <Switch checked={closeOnClickOverlay} onChange={e => setCloseOnClickOverlay(e.target.checked)} />
                </Flex>
                <Flex alignItems="center" gap="10px">
                    <div>closeOnClickOverlay: 展示遮罩层</div>
                    <Switch checked={withOverlay} onChange={e => setWithOverlay(e.target.checked)} />
                </Flex>
                <Flex alignItems="center" gap="10px">
                    <div>closeOnClickOverlay: 展示关闭按钮</div>
                    <Switch checked={withCloseIcon} onChange={e => setWithCloseIcon(e.target.checked)} />
                </Flex>

                <Button type="primary" onClick={() => setOpen(true)}>
                    打开弹窗
                </Button>
                <Modal
                    title="安萨科夫那科夫斯卡"
                    withOverlay={withOverlay}
                    withCloseIcon={withCloseIcon}
                    closeOnClickOverlay={closeOnClickOverlay}
                    open={open}
                    onClose={() => setOpen(false)}
                >
                    阿索法拉俯瞰马洛夫马斯拉夫萨罗夫拉里；三美丽；阿了；阿寺庙喇嘛
                    阿索法拉俯瞰马洛夫马斯拉夫萨罗夫拉里；三美丽；阿了；阿寺庙喇嘛
                </Modal>
            </Flex>
        </Unit>
    )
}
