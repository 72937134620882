import { Divider, Flex, Group, IconFont, Select, Text } from '@byecode/ui'
import type {
    Field,
    FieldBlockWithDsId,
    FieldInputType,
    FieldType,
    FormContainerBlockAbstract,
    SubFormBlockConfig,
    SubFormColumn
} from '@lighthouse/core'
import { type FieldInputConfigProtocol, BlockType, VariableType } from '@lighthouse/core'
import {
    FieldInputNameMap,
    findBlockById,
    findParentBlockByType,
    getAllOptions,
    getTableIcon,
    isRichTextValue,
    ListItemPaddingByecodeUi,
    RichTextEditor,
    useAtomData,
    VariableSelect
} from '@lighthouse/shared'
import { find, findIndex, reduce } from 'rambda'
import React, { useCallback, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import { syncComponentsAtom } from '@/atoms/application/state'
import { lastPageOfStackAtom, pageAtomFamily, pageBlocksAtom } from '@/atoms/page/state'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSource } from '@/hooks/useDataSource'
import { useFieldBlocksWithDsId } from '@/hooks/useFieldBlocksWithDsId'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'

import { FieldSelect } from '../../../../FieldSelect'
import { CommonOpera, Editable } from '../../UserOperate'
import { FieldInputDescMap, relativeSelectFieldList } from '../constants'
import { getSaveFieldOptions, isDisabledFieldInput } from '../helps'
import type { BaseFieldInputSetting, DataMode, FieldOptions } from '../types'
import { User } from '../User'
import { CascadeConfig, CascadeOperation } from './CascadeConfig'
import PersonConfig from './PersonConfig'
import { PhoneNumberConfig } from './PhoneNumberConfig'
import RelativeSelectConfig from './RelativeSelectConfig'
import SliderConfig from './SliderConfig'
import { TextConfig } from './TextConfig'

const TEXT_DATE_FORMAT_OPTIONS = [
    {
        label: '年/月/日 00:00',
        value: 'yyyy/MM/dd HH:mm'
    },
    {
        label: '年-月-日 00:00',
        value: 'yyyy-MM-dd HH:mm'
    },
    {
        label: '日-月-年 00:00',
        value: 'dd-MM-yyyy HH:mm'
    },
    {
        label: '年/月/日',
        value: 'yyyy/MM/dd'
    },
    {
        label: '年-月-日',
        value: 'yyyy-MM-dd'
    },
    {
        label: '日/月/年',
        value: 'dd/MM/yyyy'
    },
    {
        label: '年/月',
        value: 'yyyy/MM'
    },
    {
        label: '年-月',
        value: 'yyyy-MM'
    },
    {
        label: '年',
        value: 'yyyy'
    },
    {
        label: '时分',
        value: 'HH:mm'
    },
    {
        label: '农历-年月日 00:00',
        value: 'lunar-yyyy/MM/dd HH:mm'
    },
    {
        label: '农历-年月日',
        value: 'lunar-yyyy/MM/dd'
    },
    {
        label: '农历-时辰',
        value: 'lunar-HH:mm'
    }
]

interface DataProps extends BaseFieldInputSetting {
    mode: DataMode
    fieldOptions?: FieldOptions
}

const modeNameMap: Record<DataMode, string> = { field: '页面', form: '表单容器', subForm: '子表单' }

const VARIABLE_FIELD_TYPES = new Set<FieldInputType>([
    'date',
    'email',
    'person',
    'phoneNumber',
    'url',
    'text',
    'relativeSelect',
    'number',
    'checkbox',
    'slider',
    'cascade'
])

const SCxItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 24px;
    padding: 0 16px;
    gap: 8px;
`

export const Data: React.FunctionComponent<DataProps> = ({ mode, fieldOptions = [], pointer, prefix }) => {
    const { watch, setValue, control } = useFormContext<FieldInputConfigProtocol | SubFormBlockConfig>()
    const [inputType, fieldPointer = '', canEdit, dateFormat] = watch([
        `${prefix}inputType`,
        `${prefix}fieldPointer`,
        `${prefix}canEdit`,
        `${prefix}date.dateFormat`
    ])
    const splitFormat = dateFormat?.split('lunar-')
    const finalDateFormat = splitFormat ? splitFormat[splitFormat.length - 1] : undefined
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()

    const isUsedInForm = mode === 'form'
    const isUsedInSubForm = mode === 'subForm'
    const isUsedInField = mode === 'field'
    const isParentNotSubForm = mode !== 'subForm'

    const dataSource = useDataSource(appId, envId, pointer)

    const field = dataSource?.schema?.[fieldPointer ?? '']

    const disableEdit = useMemo(() => isDisabledFieldInput(fieldPointer, fieldOptions), [fieldOptions, fieldPointer])

    const [stackId, pageId, selectNode, blockRuntimeState] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.stackId || '', s?.pageId || '', s?.state.selectedNodes?.[0], s?.blockRuntimeState] as const, [])
    )
    const pageType = useAtomData(
        pageAtomFamily(pageId),
        useCallback(s => s?.type ?? '', [])
    )

    const { dataSourceList, prev, curr } = usePageDataSourceForVariableSelector({
        pageId,
        stackId
    })

    const [saveField, showRelativeSetting] = useMemo(() => {
        const dsField = dataSource?.schema?.[fieldPointer]
        const isRelativeSelect = dsField && !relativeSelectFieldList.has(dsField.type) && inputType === 'relativeSelect'
        return [dsField, isRelativeSelect]
    }, [dataSource?.schema, fieldPointer, inputType])

    const isShowDefaultValue = pageType !== 'edit' && (isUsedInForm || isUsedInSubForm) && saveField

    const variableField = useMemo(() => {
        if (!saveField) {
            return
        }

        // @灿白日期输入框强制绑定日期
        if (inputType === 'date') {
            return saveField.type === 'date'
                ? saveField
                : ({
                      type: 'date',
                      date: { format: finalDateFormat },
                      id: saveField.id,
                      name: saveField.name,
                      dsId: saveField.dsId,
                      dsName: saveField.dsName,
                      innerType: saveField.innerType
                  } satisfies Field)
        }
        return saveField
    }, [inputType, saveField, finalDateFormat])

    const validateFieldType = useCallback(
        (field: Field) => {
            // @灿白  日期输入框强制绑定日期
            if (inputType === 'date') {
                return field.innerType === 'DATE'
            }
            return field.innerType === saveField?.innerType
        },
        [inputType, saveField?.innerType]
    )
    const { userOption, pageOption, systemOption } = useMemo(() => {
        return getAllOptions({
            user: true,
            page: {
                prevDsId: prev.datasource?.id,
                curDsId: curr.datasource?.id
            },
            system: inputType === 'date' ? ['TODAY', 'TOMORROW', 'YESTERDAY', 'NOW'] : undefined,
            dataSourceList,
            validateField: validateFieldType
        })
    }, [prev.datasource?.id, curr.datasource?.id, inputType, dataSourceList, validateFieldType])

    const blocks = useAtomData(pageBlocksAtom(pageId))
    const syncComponents = useAtomData(syncComponentsAtom)

    const parentForm = useMemo(
        () =>
            findParentBlockByType<FormContainerBlockAbstract>({
                id: selectNode?.id ?? '',
                blocks,
                syncComponents,
                blockRuntimeState,
                filter: block => block.type === BlockType.formContainer
            }),
        [selectNode, blocks, syncComponents, blockRuntimeState]
    )

    const { fieldBlocksWithDsId } = useFieldBlocksWithDsId()

    const [saveFieldOptions, disableSaveFiledList]: [FieldOptions, FieldType[]] = useMemo(
        () => getSaveFieldOptions(inputType, fieldOptions),
        [fieldOptions, inputType]
    )

    const selectDataSourceProps = useMemo(() => {
        if (isUsedInField) {
            return
        }

        if (isUsedInSubForm) {
            const block = findBlockById(selectNode?.id ?? '', blocks)
            if (block && block.type === 'subForm') {
                const {
                    config: { columns, pointer }
                } = block

                return {
                    enable: columns.every(fieldBlock => {
                        const {
                            config: { initialValue }
                        } = fieldBlock
                        if (initialValue && initialValue.type === VariableType.SELECT_DATASOURCE) {
                            return !initialValue?.selectDataSourceVariable?.filter.expression?.conditions?.some(condition => {
                                const variable = condition?.paramList?.[0]
                                return variable?.blockId === selectNode?.id
                            })
                        }
                        return true
                    }),
                    validateFieldType,
                    fieldBlocksWithDsId: reduce<SubFormColumn, FieldBlockWithDsId[]>(
                        (p, c) => {
                            const {
                                config: { initialValue, title = '' },
                                id
                            } = c
                            if (initialValue && initialValue.type === VariableType.SELECT_DATASOURCE) {
                                return p
                            }
                            if (id !== selectNode?.id) {
                                const newFieldBlock: FieldBlockWithDsId = {
                                    ...c,
                                    type: BlockType.field,
                                    title,
                                    dsId: pointer
                                }
                                return [...p, newFieldBlock]
                            }
                            return p
                        },
                        [],
                        columns
                    )
                }
            }
        }

        // 当前输入框被作为其他输入框默认依据时，不能使用查询数据表变量
        const selectDataSourceVariableEnable = fieldBlocksWithDsId.every(fieldBlock => {
            const {
                config: { initialValue }
            } = fieldBlock
            if (initialValue && initialValue.type === VariableType.SELECT_DATASOURCE) {
                return !initialValue?.selectDataSourceVariable?.filter.expression?.conditions?.some(condition => {
                    const variable = condition?.paramList?.[0]
                    if (selectNode?.scope) {
                        return variable?.inputVariable?.blockId === selectNode?.id && variable?.inputVariable?.scope === selectNode?.scope
                    }
                    return variable?.inputVariable?.blockId === selectNode?.id
                })
            }
            return true
        })
        return {
            enable: selectDataSourceVariableEnable,
            validateFieldType,
            fieldBlocksWithDsId: fieldBlocksWithDsId.filter(fieldBlock => {
                const {
                    config: { initialValue },
                    parentFormContainerId,
                    id
                } = fieldBlock
                if (initialValue && initialValue.type === VariableType.SELECT_DATASOURCE) {
                    return false
                }
                return id !== selectNode?.id && parentFormContainerId === parentForm?.id
            })
        }
    }, [isUsedInField, isUsedInSubForm, fieldBlocksWithDsId, validateFieldType, selectNode?.id, selectNode?.scope, blocks, parentForm?.id])

    const isShowAllowMultiple =
        canEdit &&
        field &&
        inputType === 'relativeSelect' &&
        !relativeSelectFieldList.has(field.type) &&
        field.type !== 'number' &&
        field.type !== 'role'

    const operationEle = useMemo(() => {
        return (
            <>
                {isParentNotSubForm && (
                    <div style={{ padding: '0 8px' }}>
                        <Controller
                            name={`${prefix}canEdit`}
                            render={({ field }) => (
                                <Editable
                                    disabled={disableEdit}
                                    tooltipLabel="您选择的字段不支持编辑"
                                    value={field.value}
                                    onChange={val => {
                                        field.onChange(val)
                                        !val && setValue(`${prefix}required`, false)
                                    }}
                                />
                            )}
                        />
                    </div>
                )}
                {inputType === 'person' && <PersonConfig prefix={prefix} />}

                {isShowAllowMultiple && (
                    <div style={{ padding: '0 8px' }}>
                        <Controller
                            name={`${prefix}${inputType}.canMultipleChoice`}
                            render={({ field }) => <CommonOpera label="多选" value={field.value} onChange={field.onChange} />}
                        />
                    </div>
                )}
                {saveField && inputType === 'cascade' && <CascadeOperation prefix={prefix} />}
            </>
        )
    }, [disableEdit, saveField, inputType, isParentNotSubForm, isShowAllowMultiple, prefix, setValue])

    const isOperationChildren = operationEle.props.children.some(Boolean)

    return (
        <>
            <Group
                label="字段"
                styles={{
                    collapse: {
                        padding: '0!important'
                    }
                }}
            >
                <ListItemPaddingByecodeUi enablePadding alignItems="center" justifyContent="space-between">
                    <Text>写入字段</Text>
                    <Controller
                        name={`${prefix}fieldPointer`}
                        render={({ field }) => {
                            return (
                                <FieldSelect
                                    options={saveFieldOptions}
                                    position="bottom-end"
                                    value={field.value}
                                    dsId={pointer}
                                    onChange={val => {
                                        field.onChange(val)
                                        const index = findIndex(item => item.value === val, saveFieldOptions)
                                        index !== -1 && setValue(`${prefix}title`, saveFieldOptions[index].label)
                                        // const disabledEdit = isDisabledFieldInput(val, fieldOptions)
                                        // if (disabledEdit) {
                                        // setValue(`${prefix}canEdit`, false)
                                        // setValue(`${prefix}required`, false)
                                        // }
                                        const fieldType = find(item => item.value === val, saveFieldOptions)?.type
                                        if (inputType === 'relativeSelect') {
                                            if (fieldType === 'number') {
                                                setValue(`${prefix}${inputType}.canMultipleChoice`, false)
                                            }
                                            if (fieldType === 'role') {
                                                setValue(`${prefix}${inputType}.canMultipleChoice`, false)
                                                setValue(`${prefix}${inputType}.relativePointer`, 'ROLE_DATASOURCE')
                                                setValue(`${prefix}${inputType}.relativeFieldPointer`, 'ID')
                                                setValue(`${prefix}${inputType}.relativeShowFieldPointer`, 'ROLE_NAME')
                                                setValue(`${prefix}${inputType}.showType`, 'list')
                                            }
                                        }
                                    }}
                                    disableTitle={
                                        saveFieldOptions.length > 0 ? `${FieldInputNameMap[inputType]}输入框不支持以下字段：` : ''
                                    }
                                    disableList={saveFieldOptions.length > 0 ? disableSaveFiledList : []}
                                    dropdownWidth={250}
                                    emptyComponent={
                                        <Flex
                                            style={{ height: 100 }}
                                            direction="column"
                                            alignItems="center"
                                            justifyContent="center"
                                            gap={6}
                                        >
                                            <IconFont size={24} color="var(--color-gray-500)" type="Nodata-8i554976" />
                                            <Text size={14} color="var(--color-gray-500)">
                                                {FieldInputDescMap[inputType]}
                                            </Text>
                                        </Flex>
                                    }
                                />
                            )
                        }}
                    />
                </ListItemPaddingByecodeUi>
                <SCxItem>
                    {pointer ? (
                        <>
                            <Text size={12} color="var(--color-gray-500)">
                                字段来自
                            </Text>
                            <Flex gap={4}>
                                <IconFont size={16} color="var(--color-gray-400)" type={getTableIcon(dataSource)} />
                                <Text size={12} color="var(--color-gray-500)">
                                    {dataSource?.name}
                                </Text>
                            </Flex>
                        </>
                    ) : (
                        <>
                            <Text size={12} color="var(--color-red-500)">
                                {`无法选择字段，因为所在${modeNameMap[mode]}未指定数据表`}
                            </Text>
                            <IconFont size={16} color="var(--color-red-500)" type="Warning" />
                        </>
                    )}
                </SCxItem>
                {isShowDefaultValue && VARIABLE_FIELD_TYPES.has(inputType) && (
                    <ListItemPaddingByecodeUi enablePadding alignItems="center" justifyContent="space-between">
                        <Text>默认值</Text>
                        <Controller
                            name={`${prefix}initialValue`}
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <VariableSelect
                                    field={variableField}
                                    options={pageOption}
                                    value={value}
                                    systemOption={systemOption}
                                    userOption={userOption}
                                    dataSourceList={dataSourceList}
                                    selectDataSourceProps={selectDataSourceProps}
                                    onChange={onChange}
                                />
                            )}
                        />
                    </ListItemPaddingByecodeUi>
                )}
                {isShowDefaultValue && inputType === 'notes' && (
                    <Group
                        mode="none"
                        styles={{
                            root: {
                                padding: '0!important'
                            },
                            label: {
                                fontWeight: 'unset !important'
                            }
                        }}
                        label="默认值"
                    >
                        <Controller
                            name={`${prefix}initialValue`}
                            control={control}
                            render={({ field: { value, onChange } }) => {
                                const initialValue = value && value?.type === VariableType.VALUE ? value?.valueVariable?.value : ''
                                const richTextValue = isRichTextValue(initialValue) ? initialValue : null
                                return (
                                    <RichTextEditor
                                        styles={{
                                            editorContainer: {
                                                border: '1px solid var(--color-gray-200)',
                                                borderRadius: 8,
                                                marginTop: 4
                                            },
                                            editorContent: {
                                                backgroundColor: 'var(--color-gray-50)'
                                            }
                                        }}
                                        minHeight={200}
                                        config={{
                                            image: false,
                                            heading: true,
                                            variable: false
                                        }}
                                        value={richTextValue}
                                        // autofocus="end"
                                        onChange={val => onChange({ type: VariableType.VALUE, valueVariable: { value: val } })}
                                    />
                                )
                            }}
                        />
                    </Group>
                )}

                {saveField && saveField.type === 'text' && inputType === 'date' && (
                    <ListItemPaddingByecodeUi enablePadding alignItems="center" justifyContent="space-between">
                        <Text>写入格式</Text>
                        <Controller
                            name={`${prefix}date.dateFormat`}
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <Select style={{ width: 180 }} options={TEXT_DATE_FORMAT_OPTIONS} value={value} onChange={onChange} />
                            )}
                        />
                    </ListItemPaddingByecodeUi>
                )}
            </Group>
            {saveField && inputType === 'relativeSelect' && !relativeSelectFieldList.has(saveField.type) && (
                <>
                    <RelativeSelectConfig mode={mode} fieldOptions={fieldOptions} field={saveField} prefix={prefix} />
                </>
            )}
            {saveField && inputType === 'slider' && (
                <>
                    <Divider color="var(--color-gray-200)" />
                    <SliderConfig prefix={prefix} />
                </>
            )}
            {inputType === 'cascade' && <CascadeConfig pointer={pointer} prefix={prefix} />}
            <Divider color="var(--color-gray-200)" />

            {isOperationChildren && (
                <>
                    <Group
                        label="操作"
                        styles={{
                            root: {
                                padding: '12px 0!important'
                            },
                            collapse: {
                                padding: '0!important'
                            }
                        }}
                    >
                        {operationEle}
                    </Group>
                    <Divider color="var(--color-gray-200)" />
                </>
            )}

            <User pointer={pointer} mode={mode} prefix={prefix} />

            {saveField && inputType === 'phoneNumber' && (
                <>
                    <Divider color="var(--color-gray-200)" />
                    <PhoneNumberConfig prefix={prefix} />
                </>
            )}
            {saveField && isParentNotSubForm && canEdit && inputType === 'text' && (
                <>
                    <Divider style={{ margin: 0 }} color="var(--color-gray-200)" />
                    <TextConfig prefix={prefix} />
                </>
            )}
        </>
    )
}
