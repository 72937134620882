import { findParentScroller } from '@lighthouse/tools'
import { equals } from 'rambda'

import type { FlowLayoutNode, NodeIdWithScope, Rect } from '../types'
import {
    DOM_DATA_DISABLED,
    DOM_DATA_NODE_NAME,
    DOM_DATA_NODE_SCOPE,
    findNewSelectParentAndChildren,
    findNodeDom,
    findParentNodeById,
    findTriggerDom,
    layoutInteractionEventBus
} from './utils'

const BORDER_SIZE = 2

export type LayoutInteractionInstance = {
    boundary: HTMLElement
    root: HTMLElement
    scale: number
    nodesMap: Map<string, Rect>
    data: FlowLayoutNode[]
    isActive: boolean
    tappedDom: HTMLElement | null
    selectedIds: NodeIdWithScope[]
    initialCoordinate: { x: number; y: number }
    delta: { x: number; y: number }

    preventObserveCallback: boolean

    collectNodes: () => void
    updateSelectedBorder: (id: NodeIdWithScope, rect: Rect) => void
}

export interface LayoutInteractionPlugin {
    name: string

    init?: (instance: LayoutInteractionInstance) => void
    destroy?: () => void
    handleMousedown?: (event: MouseEvent) => void
    handleMouseMove?: (event: MouseEvent) => void
    handleMouseUp?: (event: MouseEvent) => void

    onNodesRectUpdate?: () => void
}

type LayoutInteractionConstructorParams<Plugin extends LayoutInteractionPlugin> = {
    data: FlowLayoutNode[]
    boundary: HTMLElement
    root: HTMLElement

    plugins?: Plugin[]
    onSelect?: (ids: NodeIdWithScope[]) => void
}

export class LayoutInteractionEngine<Plugin extends LayoutInteractionPlugin> implements LayoutInteractionInstance {
    boundary: HTMLElement

    boundaryObserver: ResizeObserver | null = null

    /** 根节点 */
    root: HTMLElement

    scroller: HTMLElement | null

    scale = 1

    /** 布局节点的位置信息 */
    nodesMap = new Map<string, Rect>()

    /** 某些节点自身的监听器， 例如自定义视图监听滚动 */
    nodeScrollListenerMap = new Map<string, HTMLElement>()

    /** 源数据 */
    data: FlowLayoutNode[] = []

    /** 鼠标事件是否开始，从外部拖拽时可能不会触发 root mousedown， 所以，需要在drag plugin中去更改 */
    isActive = false

    /** 按下时的dom元素，抬起获取节点信息时需要 */
    tappedDom: HTMLElement | null = null

    /** 选中的节点id， 为后续高级事件drag\resize做限制使用 */
    selectedIds: NodeIdWithScope[] = []

    initialCoordinate = {
        x: 0,
        y: 0
    }

    delta = {
        x: 0,
        y: 0
    }

    preventObserveCallback = false

    plugins: Plugin[] = []

    // 所有节点的尺寸订阅
    #observer: ResizeObserver | null = null

    #borderBoxDomRefs: Map<string, HTMLDivElement> = new Map()

    #hoverNode: HTMLElement | null = null

    #hoverBoxOutlineRef: HTMLDivElement | null = null

    #onSelect?: (ids: NodeIdWithScope[]) => void

    #notSelectPage = false

    constructor(props: LayoutInteractionConstructorParams<Plugin>) {
        this.boundary = props.boundary
        this.root = props.root
        this.scroller = findParentScroller(this.root)
        this.data = props.data

        this.plugins = props.plugins || []
        this.#onSelect = props.onSelect

        this.init()
    }

    updateOption(option: Partial<{ data: FlowLayoutNode[]; selectedIds: NodeIdWithScope[]; scale: number; notSelectPage?: boolean }>) {
        const { data = [], selectedIds = [], scale = 1, notSelectPage = false } = option

        // 变化时聚焦，激活快捷键前置
        if (!equals(selectedIds, this.selectedIds)) {
            if (selectedIds.length > 1) {
                this.root.focus()
            } else {
                const newId = selectedIds[selectedIds.length - 1]
                newId && findNodeDom(newId, this.root)?.focus()
            }
        }

        if (!equals(data, this.data) || !equals(selectedIds, this.selectedIds) || this.scale !== scale) {
            this.scale = scale
            this.data = data
            this.selectedIds = [...selectedIds]

            this.collectNodes()
            this.#observeNodes()
            this.#drawSelectedBorder()

            layoutInteractionEventBus.emit('updateConfig', option)
        }

        this.#notSelectPage = notSelectPage

        if (this.scroller) {
            this.scroller.removeEventListener('scroll', this.#handleScroll, true)
        }
        this.scroller = findParentScroller(this.root)
        if (this.scroller) {
            this.scroller.addEventListener('scroll', this.#handleScroll, true)
        }
    }

    init = () => {
        this.collectNodes()
        this.#observeNodes()
        this.#drawSelectedBorder()

        if (this.scroller) {
            this.scroller.addEventListener('scroll', this.#handleScroll, true)
        }

        this.root.addEventListener('mouseover', this.#mouseover)
        this.root.addEventListener('mouseout', this.#mouseout)
        this.root.addEventListener('mousedown', this.#mousedown)
        document.addEventListener('mousemove', this.#mousemove)
        document.addEventListener('mouseup', this.#mouseup)

        this.boundaryObserver = new ResizeObserver(this.#onNodesRectUpdate)
        this.boundaryObserver.observe(this.boundary)

        this.plugins.forEach(plugin => {
            plugin.init?.(this)
        })
    }

    destroy() {
        this.#observer?.disconnect()
        this.#clearSelectedBorder()

        if (this.scroller) {
            this.scroller.removeEventListener('scroll', this.#handleScroll, true)
        }
        this.root.removeEventListener('mouseover', this.#mouseover)
        this.root.removeEventListener('mouseout', this.#mouseout)
        this.root.removeEventListener('mousedown', this.#mousedown)
        document.removeEventListener('mousemove', this.#mousemove)
        document.removeEventListener('mouseup', this.#mouseup)
        this.boundaryObserver?.unobserve(this.boundary)

        this.plugins.forEach(plugin => {
            plugin.destroy?.()
        })
    }

    #onNodesRectUpdate = () => {
        if (this.preventObserveCallback) {
            return
        }
        this.collectNodes()

        this.plugins.forEach(plugin => {
            plugin.onNodesRectUpdate?.()
        })

        this.#drawSelectedBorder()
        this.#drawOutline()
    }

    #handleScroll = /* debounce(1000 / 60, this.#onNodesRectUpdate) */ this.#onNodesRectUpdate

    #mouseover = (e: MouseEvent) => {
        const triggerDom = findTriggerDom(e.target as HTMLElement, this.root)

        this.#hoverNode = triggerDom ?? this.root

        this.#drawOutline()
    }

    #mouseout = () => {
        this.#hoverNode = null

        this.#clearOutline()
    }

    #drawOutline = () => {
        this.#clearOutline()

        if (!this.#hoverNode) {
            return
        }

        const id = this.#hoverNode.getAttribute(DOM_DATA_NODE_NAME)
        const scope = this.#hoverNode.getAttribute(DOM_DATA_NODE_SCOPE) || undefined
        if (id && this.selectedIds.some(item => item.id === id && item.scope === scope)) {
            return
        }

        const boundaryRect = this.boundary.getBoundingClientRect()
        const rect = this.#hoverNode.getBoundingClientRect()

        const scale = this.boundary === this.root ? this.scale : 1

        const offsetX = (-boundaryRect.left + rect.left) / scale
        const offsetY = (-boundaryRect.top + rect.top) / scale

        const outline = document.createElement('div')
        outline.style.position = 'absolute'
        outline.style.zIndex = '10'
        outline.style.left = '0'
        outline.style.top = '0'
        outline.style.transform = `translate3d(${offsetX}px, ${offsetY}px, 0)`
        outline.style.width = `${rect.width / scale}px`
        outline.style.height = `${rect.height / scale}px`
        outline.style.outline = `${BORDER_SIZE}px dashed #5551ff80`
        outline.style.outlineOffset = `${-BORDER_SIZE + 1}px`
        outline.style.pointerEvents = 'none'

        this.#hoverBoxOutlineRef = outline

        this.boundary.append(this.#hoverBoxOutlineRef)
    }

    #clearOutline = () => {
        this.#hoverBoxOutlineRef?.remove()
        this.#hoverBoxOutlineRef = null
    }

    #mousedown = (e: MouseEvent) => {
        if (e.button !== 0) {
            return
        }

        const target = e.target as HTMLElement

        this.tappedDom = target
        this.isActive = true
        this.initialCoordinate = {
            x: e.clientX,
            y: e.clientY
        }

        this.plugins.forEach(plugin => {
            plugin.handleMousedown?.(e)
        })
    }

    #mousemove = (e: MouseEvent) => {
        this.delta = {
            x: e.clientX - this.initialCoordinate.x,
            y: e.clientY - this.initialCoordinate.y
        }

        if (!this.isActive) {
            return
        }

        this.plugins.forEach(plugin => {
            plugin.handleMouseMove?.(e)
        })
    }

    #mouseup = (e: MouseEvent) => {
        if (!this.isActive) {
            return
        }

        const isShiftKey = e.shiftKey

        const triggerDom = findTriggerDom(this.tappedDom, this.root)
        const newSelectedId = triggerDom?.getAttribute(DOM_DATA_NODE_NAME)
        const newSelectedNode = newSelectedId
            ? {
                  scope: triggerDom?.getAttribute(DOM_DATA_NODE_SCOPE) || undefined,
                  id: newSelectedId
              }
            : null

        let hasChanged = false

        if (isShiftKey) {
            window.getSelection()?.removeAllRanges()
            if (!newSelectedNode) {
                return
            }

            if (this.selectedIds.some(item => item.id === newSelectedNode.id && item.scope === newSelectedNode.scope)) {
                this.selectedIds = this.selectedIds.filter(item => item.id !== newSelectedNode.id || item.scope !== newSelectedNode.scope)
            } else {
                const { parent, children } = findNewSelectParentAndChildren(newSelectedNode, this.selectedIds, this.data)

                // 如果有父级选中了，则取消选中父级
                if (parent) {
                    this.selectedIds = this.selectedIds.filter(item => item.scope !== parent.scope || item.id !== parent.id)
                }

                // 如果有子级选中了，则取消选中子级
                if (children.length > 0) {
                    this.selectedIds = this.selectedIds.filter(
                        item => !children.some(child => child.scope === item.scope && child.id === item.id)
                    )
                }

                this.selectedIds = [...this.selectedIds, newSelectedNode]
                this.root.focus()
            }

            hasChanged = true
        } else {
            hasChanged = !equals(this.selectedIds, newSelectedNode ? [newSelectedNode] : [])
            this.selectedIds = newSelectedNode ? [newSelectedNode] : []
            hasChanged && newSelectedNode && findNodeDom(newSelectedNode, this.root)?.focus()
        }

        if (hasChanged) {
            this.#drawSelectedBorder()
            layoutInteractionEventBus.emit('updateConfig', { selectedIds: this.selectedIds })
            this.#onSelect?.(this.selectedIds)
        } else if (this.#notSelectPage) {
            // 没有选中页面级时，例如导航栏，则触发选中aside type
            this.#onSelect?.(this.selectedIds)
        }

        this.plugins.forEach(plugin => {
            plugin.handleMouseUp?.(e)
        })

        this.isActive = false
        this.tappedDom = null
    }

    /** 收集节点的位置信息 */
    collectNodes() {
        this.nodesMap.clear()
        const nodes = this.root.querySelectorAll<HTMLElement>(`[${DOM_DATA_NODE_NAME}]:not([${DOM_DATA_DISABLED}])`)

        this.nodesMap.set('@root', this.root.getBoundingClientRect())
        nodes.forEach(el => {
            const id = el.getAttribute(DOM_DATA_NODE_NAME)
            const scope = el.getAttribute(DOM_DATA_NODE_SCOPE)
            if (!id) {
                return
            }

            this.nodesMap.set(`${scope || ''}@${id}`, el.getBoundingClientRect())
        })
    }

    #observeNodes() {
        if (this.#observer) {
            this.#observer.disconnect()
        } else {
            this.#observer = new ResizeObserver(([e]) => {
                if (this.preventObserveCallback) {
                    return
                }
                this.#onNodesRectUpdate()
            })
        }

        this.nodesMap.forEach((_, info) => {
            const [scope, id] = info.split('@')
            const target = findNodeDom({ id, scope }, this.root)
            if (!target) {
                return
            }
            this.#observer?.observe(target)
        })
    }

    #drawSelectedBorder() {
        this.#clearSelectedBorder()

        const boundaryRect = this.boundary.getBoundingClientRect()

        const scale = this.boundary === this.root ? this.scale : 1

        this.selectedIds.forEach(node => {
            const selectedRect = this.nodesMap.get(`${node.scope || ''}@${node.id}`)
            if (!selectedRect) {
                return
            }

            const borderContainer = document.createElement('div')
            const offsetX = (-boundaryRect.left + selectedRect.left) / scale
            const offsetY = (-boundaryRect.top + selectedRect.top) / scale
            borderContainer.style.position = 'absolute'
            borderContainer.style.zIndex = '10'
            borderContainer.style.left = '0'
            borderContainer.style.top = '0'
            borderContainer.style.transform = `translate3d(${offsetX}px, ${offsetY}px, 0)`
            borderContainer.style.width = `${selectedRect.width / scale}px`
            borderContainer.style.height = `${selectedRect.height / scale}px`
            borderContainer.style.outline = `${BORDER_SIZE}px solid #5551ff`
            borderContainer.style.outlineOffset = `${-BORDER_SIZE + 1}px`
            borderContainer.style.pointerEvents = 'none'

            this.#borderBoxDomRefs.set(`${node.scope || ''}@${node.id}`, borderContainer)
        })

        // 显示选中的元素父级虚线
        if (this.selectedIds.length === 1) {
            const parentNode = findParentNodeById(this.selectedIds[0], this.data)
            if (parentNode) {
                const parentElm = findNodeDom(parentNode, this.root)
                if (parentElm) {
                    const rect = parentElm.getBoundingClientRect()

                    const scale = this.boundary === this.root ? this.scale : 1

                    const offsetX = (-boundaryRect.left + rect.left) / scale
                    const offsetY = (-boundaryRect.top + rect.top) / scale

                    const outline = document.createElement('div')
                    outline.style.position = 'absolute'
                    outline.style.zIndex = '10'
                    outline.style.left = '0'
                    outline.style.top = '0'
                    outline.style.transform = `translate3d(${offsetX}px, ${offsetY}px, 0)`
                    outline.style.width = `${rect.width / scale}px`
                    outline.style.height = `${rect.height / scale}px`
                    outline.style.outline = `${BORDER_SIZE}px dashed #5551ff80`
                    outline.style.outlineOffset = `${-BORDER_SIZE + 1}px`
                    outline.style.pointerEvents = 'none'

                    this.#borderBoxDomRefs.set(`${parentNode.scope || ''}@${parentNode.id}`, outline)
                }
            }
        }

        this.boundary.append(...this.#borderBoxDomRefs.values())
    }

    updateSelectedBorder(node: NodeIdWithScope, rect: Rect) {
        const container = this.#borderBoxDomRefs.get(`${node.scope || ''}@${node.id}`)
        if (!container) {
            return
        }

        const scale = this.boundary === this.root ? this.scale : 1

        const boundaryRect = this.boundary.getBoundingClientRect()
        const offsetX = (-boundaryRect.left + rect.left) / scale
        const offsetY = (-boundaryRect.top + rect.top) / scale
        container.style.transform = `translate3d(${offsetX}px, ${offsetY}px, 0)`
        container.style.width = `${rect.width / scale}px`
        container.style.height = `${rect.height / scale}px`
    }

    #clearSelectedBorder() {
        this.#borderBoxDomRefs.forEach(el => {
            el.remove()
        })

        this.#borderBoxDomRefs.clear()
    }
}
