import type { RefObject } from 'react'
import type React from 'react';
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'

import { isIosPlatform } from './browser'
import { getActualWidthOfChars, getStyle, getStyleToNumber } from './helper'
import type { UseUncontrolledInput } from './types'
import { Enum } from './types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useMounted(f: () => any): void {
    const mounted = useRef(false)

    useEffect(() => {
        if (mounted.current) {
            return
        }
        mounted.current = true
        return f()
    }, [f])
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useLayoutMounted(f: () => any): void {
    const mounted = useRef(false)

    useLayoutEffect(() => {
        if (mounted.current) {
            return
        }
        mounted.current = true
        return f()
    }, [f])
}

/**
 * 函数防抖让 value 延时改变提高性能
 * @export
 * @param {*} value
 * @param {number} delay
 * @return {*}
 */
export function useDebounce<T>(value: T, delay: number): T {
    const [debounceValue, setDebounceValue] = useState(value)
    useEffect(() => {
        const timer = setTimeout(() => {
            setDebounceValue(value)
        }, delay)
        return () => {
            clearTimeout(timer)
        }
    }, [value, delay])

    return debounceValue
}

export interface UseDelayedRenderOptions {
    enterDelay?: number
    exitDelay?: number
    onUnmount?: () => void
}

export const useDelayedRender = (active = false, options: UseDelayedRenderOptions = {}) => {
    const [, force] = useState<unknown>()
    const mounted = useRef(active)
    const rendered = useRef(false)
    const renderTimer = useRef<number | null>(null)
    const unmountTimer = useRef<number | null>(null)
    const prevActive = useRef(active)

    const recalculate = useCallback(() => {
        const { enterDelay = 1, exitDelay = 0 } = options

        if (prevActive.current) {
            // Mount immediately
            mounted.current = true
            if (unmountTimer.current) {
                clearTimeout(unmountTimer.current)
            }

            if (enterDelay <= 0) {
                // Render immediately
                rendered.current = true
            } else {
                if (renderTimer.current) {
                    return
                }
                // Render after a delay
                renderTimer.current = window.setTimeout(() => {
                    rendered.current = true
                    renderTimer.current = null
                    force({})
                }, enterDelay)
            }
        } else {
            // Immediately set to unrendered
            rendered.current = false

            if (exitDelay <= 0) {
                mounted.current = false
            } else {
                if (unmountTimer.current) {
                    return
                }

                // Unmount after a delay
                unmountTimer.current = window.setTimeout(() => {
                    mounted.current = false
                    unmountTimer.current = null
                    force({})
                }, exitDelay)
            }
        }
    }, [options])

    // When the active prop changes, need to re-calculate
    if (active !== prevActive.current) {
        prevActive.current = active
        // We want to do this synchronously with the render, not in an effect
        // this way when active → true, mounted → true in the same pass
        recalculate()
    }

    return {
        mounted: mounted.current,
        rendered: rendered.current
    }
}

export const BreakPointSize = Enum('xs', 'sm', 'md', 'lg')
export type BreakPointSize = Enum<typeof BreakPointSize>

export function getBreakpointSize(width: number) {
    let size: BreakPointSize = BreakPointSize.md
    if (width >= 720) {
        size = BreakPointSize.lg
    } else if (width >= 598) {
        size = BreakPointSize.md
    } else if (width >= 440) {
        size = BreakPointSize.sm
    } else {
        size = BreakPointSize.xs
    }
    return size
}

/**
 * 获取响应式断点信息
 */
// eslint-disable-next-line etc/no-misused-generics
export const useBreakpoint = <T extends HTMLElement = HTMLDivElement>(deps: React.DependencyList = []) => {
    const ref = useRef<T | null>(null)

    const [width, setWidth] = useState<number>(0)
    const [breakPoint, setBreakPoint] = useState<BreakPointSize>()

    useLayoutEffect(() => {
        const container = ref.current
        if (!container) {
            return
        }

        const { width } = container.getBoundingClientRect()
        const size = getBreakpointSize(width)
        setWidth(width)
        setBreakPoint(size)

        const observer = new ResizeObserver(es => {
            const { contentRect } = es[es.length - 1]
            const { width } = contentRect
            const size = getBreakpointSize(width)
            setWidth(width)
            setBreakPoint(size)
        })

        observer.observe(container)

        return () => observer.unobserve(container)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps)

    return { ref, breakPoint, width }
}

/**
 *
 * @param param0
 * @returns
 *  在使用的组件内：
    const [_value, handleChange] = useUncontrolled({
        value: contextProps.checked ?? checked,
        defaultValue: defaultChecked,
        finalValue: false,
    });

    使用的地方用：_value
    组件改值的地方：
        ctx ? contextProps.onChange(event) : onChange?.(event);
        handleChange(event.currentTarget.checked);
 */
export function useUncontrolled<T>({
    value,
    defaultValue,
    finalValue,
    // eslint-disable-next-line no-empty-function
    onChange = () => {}
}: UseUncontrolledInput<T>): [T, (value: T) => void, boolean] {
    const [uncontrolledValue, setUncontrolledValue] = useState(defaultValue === undefined ? finalValue : defaultValue)

    const handleUncontrolledChange = (val: T) => {
        setUncontrolledValue(val)
        onChange?.(val)
    }

    if (value !== undefined) {
        return [value as T, onChange, true]
    }

    return [uncontrolledValue as T, handleUncontrolledChange, false]
}

/**
 * 获取当前文本dom文本是否被省略
 * 使用时建议直接绑定文本标签
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useTextOmit = function <T extends HTMLElement = any>(dependencies?: string[]) {
    const ref = useRef<T>(null)
    const [isOmit, setIsOmit] = useState(false)
    const [rangeWidth, setRangeWidth] = useState(0)
    const [targetWidth, setTargetWidth] = useState(0)
    const frameID = useRef(0)
    const currentDependencies = useMemo(() => dependencies, [dependencies])

    const changeIsOmit = useCallback((target: HTMLElement) => {
        const options = { size: getStyleToNumber(target, 'font-size'), family: getStyle(target, 'font-family') ?? 'Microsoft YaHei' }
        const rangeWidth = getActualWidthOfChars(target.textContent ?? '', options)
        setRangeWidth(rangeWidth)
        const otherMargin =
            getStyleToNumber(target, 'padding-right') +
            getStyleToNumber(target, 'padding-left') +
            getStyleToNumber(target, 'border-left-width') +
            getStyleToNumber(target, 'border-right-width')
        const targetWidth = getStyleToNumber(target, 'width') - otherMargin
        setTargetWidth(targetWidth)
        if (rangeWidth > targetWidth) {
            // console.log('有隐藏文字...')
            setIsOmit(true)
            return
        }
        // console.log('没有隐藏文字')
        setIsOmit(false)
    }, [])


    const observerCallback = useCallback(
        (entries: MutationRecord[]) => {
            const entry = entries[0]
            if (entry) {
                cancelAnimationFrame(frameID.current)
                frameID.current = requestAnimationFrame(() => {
                    if (ref.current) {
                        const { target } = entry
                        if (target instanceof HTMLElement) {
                            changeIsOmit(target)
                            return
                        }
                        changeIsOmit(target.parentNode as HTMLElement)
                    }
                })
            }
        },
        [changeIsOmit]
    )

    useEffect(() => {
        if (!ref.current) {
            return
        }
        const target = ref.current
        changeIsOmit(target)
    }, [currentDependencies, changeIsOmit])

    useEffect(() => {
        const observer = new MutationObserver(observerCallback)
        if (ref.current) {
            observer?.observe(ref.current, {
                childList: true,
                characterData: true,
                attributes: true,
                subtree: true
            })
        }
        return () => {
            observer?.disconnect()
            if (frameID.current) {
                cancelAnimationFrame(frameID.current)
            }
        }
    }, [observerCallback])

    return { ref, isOmit, targetWidth, rangeWidth }
}

export const useDomHeight: () => [RefObject<HTMLTextAreaElement>, number | undefined] = () => {
    const domRef = useRef<HTMLTextAreaElement>(null)

    // const dom = document.querySelector<HTMLDivElement>(domId)
    const [height, setHeight] = useState<number | undefined>(domRef.current?.scrollHeight)

    const obsCallback = useCallback((mutations: MutationRecord[], observer: MutationObserver) => {
        if (mutations.length > 0 && domRef.current) {
            if (!domRef.current.scrollHeight) {
                setHeight(domRef.current.clientHeight)
                return
            }
            setHeight(domRef.current.scrollHeight)
            // heightRef.current = mutations[0].target.scrollHeight || undefined
        }
    }, [])

    useEffect(() => {
        if (!domRef.current) {
            return
        }
        const target = domRef.current
        if (!target.scrollHeight) {
            setHeight(target.clientHeight)
            return
        }
        setHeight(target.scrollHeight)
    }, [])

    useEffect(() => {
        if (!domRef.current) {
            return
        }
        const mutationObserver = new MutationObserver(obsCallback)
        mutationObserver.observe(domRef.current, {
            childList: true,
            attributes: true,
            attributeFilter: ['style'],
            attributeOldValue: true
            // characterData: true
        })

        return () => {
            mutationObserver.disconnect()
        }
    }, [obsCallback])

    return [domRef, height]
}

export const useIndicatorFontWidth = () => {
    const ref = useRef<HTMLDivElement>(null)
    const frameID = useRef(0)
    const [state, setState] = useState({
        width: 0,
        height: 0
    })

    const { width, height } = state

    const getContainerWidth = useCallback((target: HTMLElement) => {
        const verticalMargin =
            getStyleToNumber(target, 'padding-right') +
            getStyleToNumber(target, 'padding-left') +
            getStyleToNumber(target, 'border-left-width') +
            getStyleToNumber(target, 'border-right-width')

        const horizontalMargin =
            getStyleToNumber(target, 'padding-right') +
            getStyleToNumber(target, 'padding-left') +
            getStyleToNumber(target, 'border-left-width') +
            getStyleToNumber(target, 'border-right-width')
        return [target.offsetWidth - horizontalMargin, target.offsetHeight - verticalMargin]
    }, [])
    const observerCallback = useCallback(
        (entries: ResizeObserverEntry[]) => {
            const entry = entries[0]
            if (entry) {
                cancelAnimationFrame(frameID.current)
                frameID.current = requestAnimationFrame(() => {
                    if (ref.current) {
                        const { target } = entry
                        const [containerWidth, containerHeight] = getContainerWidth(target as HTMLDivElement)
                        setState({
                            width: containerWidth,
                            height: containerHeight
                        })
                    }
                })
            }
        },
        [getContainerWidth]
    )

    useEffect(() => {
        if (!ref.current) {
            return
        }
        const mutationObserver = new ResizeObserver(observerCallback)
        mutationObserver.observe(ref.current)

        return () => {
            mutationObserver.disconnect()
            if (frameID.current) {
                cancelAnimationFrame(frameID.current)
            }
        }
    }, [observerCallback])

    return { ref, width, height }
}

// 修复ios下关闭键盘后，实际的元素定位错乱问题
export const useIosOpenKeyboardFix = () => {
    const scrollTopRef = useRef(0)

    const elFocusHandle = useCallback((e: FocusEvent) => {
        if (e.target instanceof HTMLElement && (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA')) {
            scrollTopRef.current = document.documentElement.scrollTop
        }
    }, [])

    const elBlurHandle = useCallback((e: FocusEvent) => {
        if (e.target instanceof HTMLElement && (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA')) {
            setTimeout(() => {
                window.scrollTo(0, scrollTopRef.current)
            }, 0)
        }
    }, [])

    useEffect(() => {
        if (isIosPlatform()) {
            document.addEventListener('focus', elFocusHandle, true)
            window.addEventListener('blur', elBlurHandle, true)
        }

        return () => {
            document.removeEventListener('focus', elFocusHandle, true)
            window.removeEventListener('blur', elBlurHandle, true)
        }
    }, [elBlurHandle, elFocusHandle])
}
