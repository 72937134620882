import type { FlattenSimpleInterpolation } from 'styled-components'
import { css } from 'styled-components'

export const pointer = (): FlattenSimpleInterpolation => css`
    cursor: pointer;
`

export const defaultCursor = (): FlattenSimpleInterpolation => css`
    user-select: none;
    cursor: default;
`
