import { Divider, Group } from '@byecode/ui'
import type { BackgroundProtocol, EdgeValue, TabsBlockConfig, TabsType } from '@lighthouse/core'
import { BackgroundColorProtocol, RadiusProtocol, TABS_TYPE } from '@lighthouse/core'
import { ListItem4ByecodeUi, useAtomAction, useAtomData } from '@lighthouse/shared'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import type { ControllerRenderProps } from 'react-hook-form'
import { Controller, useFormContext } from 'react-hook-form'

import { blockHighlightAtom, lastPageOfStackAtom } from '@/atoms/page/state'
import { getBlockAndPageDesignLimit, PositionSetting, SizeSetting } from '@/components/DesignSetting'
import AnimationSetting from '@/components/DesignSetting/AnimationSetting'
import { FontSetting } from '@/components/DesignSetting/FontSetting'
import { useSetExtendsKeys } from '@/components/DesignSetting/hooks/useMouseEventDistance'
import { StyleSetting } from '@/components/DesignSetting/StyleSetting'
import { VisibilitySetting } from '@/components/VisibilitySetting'

import { BlockConfigureSegmentedControl } from '../Common/BlockConfigureSegmentedControl'
import FormSegmentedControl from '../Common/FormSegmentedControl'
import type { BlockSettingsSegmentedEnum } from '../constants'
import { BLOCK_SETTINGS_SEGMENTED_ENUM, POSITION_ALIGN_CONTROL } from '../constants'
import TabBarSettings from './TabBarSettings'
import { TabsTypeSelector } from './TabsTypeSelector'



const initTabStyleConfig: Record<string, { background?: BackgroundProtocol; radius: EdgeValue }> = {
    LINE: {
        radius: [0, 0, 0, 0]
    },
    ROUND: {
        radius: [100, 100, 100, 100]
    },
    CARD_SLOT: {
        radius: [0, 0, 0, 0]
    },
    SEGMENTED: {
        background: {
            type: 'color',
            color: '#eceff4'
        },
        radius: [100, 100, 100, 100]
    }
}

export const TabsSettings: React.FC = () => {
    const { handleSetBreakKeys } = useSetExtendsKeys()
    const designProps = getBlockAndPageDesignLimit('tabs')
    const { control, watch, setValue } = useFormContext<TabsBlockConfig>()
    const [variant] = watch(['variant'])
    // const isLineType = variant === TABS_TYPE.LINE
    const isSegmentedType = variant === TABS_TYPE.SEGMENTED

    const [currentTab, setCurrentTab] = useState<BlockSettingsSegmentedEnum>(BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN)

    const { run: setBlockHighlight } = useAtomAction(blockHighlightAtom)

    useEffect(() => {
        setBlockHighlight([])
    }, [setBlockHighlight])

    return (
        <>
            <BlockConfigureSegmentedControl value={currentTab} onChange={setCurrentTab} />

            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN && (
                <>
                    <Group label="标签类型">
                        <ListItem4ByecodeUi grow>
                            <Controller
                                name="variant"
                                control={control}
                                render={({ field }) => (
                                    <TabsTypeSelector name="variant" value={field.value} onChange={val => {
                                        const init = initTabStyleConfig[val]
                                        setValue('breakPoint.design.background', init.background)
                                        setValue('breakPoint.design.radius', init.radius)
                                        field.onChange(val)
                                        handleSetBreakKeys(['breakPoint.design.background', 'breakPoint.design.radius'])
                                    }} />
                                )}
                            />
                        </ListItem4ByecodeUi>
                    </Group>

                    <Divider />

                    <>
                        <PositionSetting {...designProps.position} />
                        <Divider />
                        <SizeSetting {...designProps.size} />
                        <Divider />
                        <Group label="布局">
                            <Controller
                                control={control}
                                name={`variantMap.${variant}.align`}
                                render={({ field }) => (
                                    <FormSegmentedControl
                                        {...field}
                                        style={{ flex: '0 0 180px' }}
                                        label="对齐"
                                        data={POSITION_ALIGN_CONTROL}
                                    />
                                )}
                            />
                        </Group>

                        <Divider />

                        <StyleSetting {...designProps.design} hideLineColor={variant !== TABS_TYPE.LINE} />
                    </>

                    <Divider />

                    <FontSetting {...designProps.font} />
                </>
            )}

            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.CONFIGURE && (
                <>
                    <Group label="标签">
                        <TabBarSettings variant={variant} isSegmentedType={isSegmentedType} />
                    </Group>
                    <Divider />
                    <VisibilitySetting />
                </>
            )}

            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.INTERACTION && <AnimationSetting />}
        </>
    )
}
