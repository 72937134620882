import { hideScrollBar } from '@byecode/ui'
import type { FloatingContext } from '@floating-ui/react'
import type { AnimationControlSetting, AnimationTypeEnum } from '@lighthouse/core'
import { BACKGROUND_TYPE } from '@lighthouse/core'
import { ColorLibraryEditor, FillPicker, ShadowController, useFillPickerContext } from '@lighthouse/shared'
import isDeepEqual from 'fast-deep-equal'
import { mergeDeepRight } from 'rambda'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'

import { useColorSystemAction } from '@/hooks/useColorSystemAction'

import AnimationSetting from './AnimationSetting'
import { AnimationSettingHeader } from './AnimationSettingHeader'
import TransitionSetting from './TransitionSetting'

interface AnimationSettingControllerProps {
    value: AnimationControlSetting
    title?: string
    type: AnimationTypeEnum
    popoverContext:FloatingContext
    style?: React.CSSProperties
    onChange: (v: AnimationControlSetting) => void
    onClose: () => void
    onBack?: () => void
}
const SCxContainer = styled.div`
    width: 300px;
`

const SCxTab = styled.div`
    width: 100%;
    padding: 0 16px;
    max-height: 600px;
    overflow: hidden auto;
    ${hideScrollBar()}
`

const AnimationSettingController: React.FunctionComponent<AnimationSettingControllerProps> = ({
    value,
    title,
    type,
    popoverContext,
    style,
    onBack,
    onChange,
    onClose
}) => {
    const { transition, shadow, background } = value

    const [showType, setShowType] = useState<'animation' | 'transition' | 'shadow' | 'background' | 'colorEdit' | 'colorCreat'>('animation')
    const [editId, setEditId] = useState('')
    const colorSystemMethods = useColorSystemAction()

    const { palettes } = useFillPickerContext()

    const handleChange = useCallback(
        (v: Partial<AnimationControlSetting>) => {
            onChange(mergeDeepRight(value, v))
        },
        [onChange, value]
    )

    useEffect(() => {
        popoverContext?.update()
    }, [popoverContext, showType])

    return (
        <SCxContainer style={style}>
            <SCxTab hidden={showType !== 'animation'}>
                <AnimationSettingHeader title={title} onClose={onClose} onBack={onBack} />
                <AnimationSetting value={value} type={type} onChange={handleChange} onOpen={v => setShowType(v)} />
            </SCxTab>
            <SCxTab hidden={showType !== 'transition'}>
                <AnimationSettingHeader title="过渡" onClose={onClose} onBack={() => setShowType('animation')} />
                <TransitionSetting value={transition} onChange={v => handleChange({ transition: v })} />
            </SCxTab>
            <SCxTab hidden={showType !== 'shadow'}>
                <AnimationSettingHeader title="阴影" onClose={onClose} onBack={() => setShowType('animation')} />
                <ShadowController
                    value={shadow}
                    onChange={val => {
                        if (isDeepEqual(val, shadow)) {
                            return
                        }
                        handleChange({ shadow: val })
                    }}
                    {...colorSystemMethods}
                />
            </SCxTab>
            {showType === 'background' && (
                <SCxTab hidden={showType !== 'background'}>
                    <AnimationSettingHeader title="背景" onClose={onClose} onBack={() => setShowType('animation')} />
                    <FillPicker
                        onClickCreateColor={() => {
                            setShowType('colorCreat')
                        }}
                        onClickEditColor={(id: string) => {
                            setShowType('colorEdit')
                            setEditId(id)
                        }}
                        enabledBackgroundTypes={[BACKGROUND_TYPE.color, BACKGROUND_TYPE.gradient]}
                        value={background}
                        onChange={val => {
                            handleChange({ background: val })
                        }}
                        {...colorSystemMethods}
                    />
                </SCxTab>
            )}
            <SCxTab hidden={showType !== 'colorCreat'}>
                <AnimationSettingHeader title="创建颜色" onClose={onClose} onBack={() => setShowType('background')} />
                <ColorLibraryEditor
                    isCreateMode
                    onCreate={v => {
                        colorSystemMethods.onCreateColor?.(v)
                        setShowType('background')
                    }}
                />
            </SCxTab>
            <SCxTab hidden={showType !== 'colorEdit'}>
                <AnimationSettingHeader
                    title="编辑颜色"
                    onClose={onClose}
                    onBack={() => {
                        setShowType('background')
                        setEditId('')
                    }}
                />
                <ColorLibraryEditor
                    value={palettes.find(item => item.id === editId)}
                    onChange={v => {
                        editId && colorSystemMethods.onUpdateColor?.({ ...v, id: editId })
                        // setShowType('background')
                    }}
                />
            </SCxTab>
        </SCxContainer>
    )
}

export default AnimationSettingController
