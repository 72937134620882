import { Button, Divider, Flex, IconFont, Tooltip } from '@byecode/ui'
import { useAtomAction, useAtomData } from '@lighthouse/shared'
import React, { useCallback } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'

import { APPLICATION_LAYOUT_MODE_TYPE } from '@/atoms/application/constants'
import { setCurrentDataSourceIdAtom } from '@/atoms/dataSource/action'
import { workflowListAtom } from '@/atoms/workflow/state'
import { useCurrentAppID } from '@/hooks/useApplication'

import { useMatchRouteToMode, useRecentPageHistory } from '../help'
import { ApplicationActions } from './Actions'
import { ApplicationInfo } from './ApplicationInfo'
import * as SC from './styles'

const TABS = [
    {
        label: '数据',
        icon: <IconFont type="NewTable" size={20} />,
        value: APPLICATION_LAYOUT_MODE_TYPE.dataSource,
        path: './dataSource'
    },
    {
        label: '设计',
        icon: <IconFont type="RulerCrossPen" size={20} />,
        value: APPLICATION_LAYOUT_MODE_TYPE.preview,
        path: './page'
    },
    {
        label: '流程',
        icon: <IconFont type="panel" size={20} />,
        value: APPLICATION_LAYOUT_MODE_TYPE.flow,
        path: './flow'
    }
]

const NAV_BUTTONS = [
    {
        label: '分析',
        icon: 'Charts',
        value: './analyze/traffic'
    },
    {
        label: '集成',
        icon: 'Gallery',
        value: './otherPlatforms'
    },
    {
        label: '设置',
        icon: 'Setting',
        value: './settings'
    }
]

interface ApplicationHeaderProps {
    appId: string
    onClose?: () => void
    onOpenSetting?: () => void
}

const ApplicationHeader: React.FC<ApplicationHeaderProps> = () => {
    const workflowList = useAtomData(workflowListAtom)
    const modeType = useMatchRouteToMode()
    const navigate = useNavigate()

    const appId = useCurrentAppID()
    const { flowId, dsId, dsPath } = useRecentPageHistory(appId)

    const { run: setCurrentDataSourceId } = useAtomAction(setCurrentDataSourceIdAtom)

    const onTabChange = useCallback(
        (tab: string) => {
            switch (tab) {
                case APPLICATION_LAYOUT_MODE_TYPE.preview: {
                    navigate({ pathname: `./page` })
                    break
                }

                case APPLICATION_LAYOUT_MODE_TYPE.flow: {
                    const flow = workflowList.find(item => item.id === flowId)
                    // 为了解决线上版本没有编辑版本工作流的问题
                    if (flow) {
                        navigate({ pathname: `./flow/${flowId}` })
                        return
                    }
                    navigate({ pathname: `./flow` })
                    break
                }

                case APPLICATION_LAYOUT_MODE_TYPE.dataSource: {
                    setCurrentDataSourceId({ dsId })
                    navigate({ pathname: `.${dsPath}` })
                    break
                }

                default: {
                    break
                }
            }
        },
        [dsId, dsPath, flowId, navigate, setCurrentDataSourceId, workflowList]
    )

    return (
        <SC.Container>
            <SC.Header>
                <Flex alignItems="center" style={{ flex: 1, overflow: 'hidden' }}>
                    <Tooltip title="回到工作台">
                        <Link to="/workbench">
                            <SC.WorkBench type="text" icon={<IconFont type="Home" fill="var(--color-gray-400)" size={20} />} />
                        </Link>
                    </Tooltip>
                    <Divider direction="vertical" styles={{ root: { margin: '8px 14px 8px 6px' } }} />
                    <ApplicationInfo />
                </Flex>

                <SC.PositionCenter>
                    <SC.StyledSegmentControl size="lg" data={TABS} value={modeType} onChange={onTabChange} />
                </SC.PositionCenter>

                <Flex alignItems="center" justifyContent='flex-end' gap={8} style={{ flex: 1 }}>
                    {NAV_BUTTONS.map(item => (
                        <Tooltip key={item.value} title={item.label}>
                            <NavLink to={item.value}>
                                <SC.NavButton type="text" icon={<IconFont type={item.icon} size={20} />} />
                            </NavLink>
                        </Tooltip>
                    ))}

                    <ApplicationActions />
                </Flex>
            </SC.Header>
        </SC.Container>
    )
}

export default ApplicationHeader
