import type {
    BaseBreakPointConfigProtocol,
    BlockAbstract,
    DataSourceAbstract,
    DataSourceBase,
    GroupConfigure,
    PageAbstract,
    TextBlockConfig
} from '@lighthouse/core'
import { BlockType } from '@lighthouse/core'
import type { NodeIdWithScope } from '@lighthouse/shared'
import {
    ANONYMOUS,
    ApplicationPreviewEnum,
    deepMerge,
    EMPTY_COLUMN_GROUP,
    FindUseLocationType,
    FindUseObjectContextProvider,
    getBreakPointConfigure,
    hasChildrenBlock,
    mergeSingleBreakPointConfigure,
    PERSON_ID,
    systemPersonFieldId,
    useApplicationContext
} from '@lighthouse/shared'
import { deepOmitNil } from '@lighthouse/tools'
import fastEqual from 'fast-deep-equal/es6'
import { useSetAtom } from 'jotai'
import { clone, findIndex } from 'rambda'
import React, { useCallback, useEffect, useMemo } from 'react'
import type { DeepPartial } from 'react-hook-form'
import { FormProvider, useForm } from 'react-hook-form'
import { useLatest } from 'react-use'

import { BlockSettingProvider } from '@/contexts/BlockSettingContext'
import { BreakPointProvider } from '@/contexts/BreakPointContext'
import { usePreviewType } from '@/hooks/useApplication'
import { useFindUse } from '@/hooks/useFindUse'

import { applicationAtom, dataSourceAtom, dataSourceListIdsAtom } from './atoms'
import { BreadcrumbSetting } from './Breadcrumb'
import { ButtonSetting } from './Button'
import { CardBlockSetting } from './Card'
import { ChartBlockSettings } from './Chart'
import { CollapseSetting } from './Collapse'
import { ContainerSetting } from './Container'
import { CustomViewSetting } from './CustomView'
import { DividerBlockSetting } from './Divider'
import BlockFieldSettings from './Field'
import { FieldGroupSetting } from './FieldGroup'
import FileBlockSetting from './File'
import { FilterSetting } from './Filter'
import { FloatSetting } from './Float'
import { IconSetting } from './Icon'
import { IframeSetting } from './Iframe'
import { BlockImageSettings } from './Image'
import { OnOffSetting } from './OnOff'
import { QrBarcodeSetting } from './QrBarcode'
import * as SC from './styles'
import { SubFormSetting } from './SubForm'
import { TabsSettings } from './Tabs'
import { TextBlockSetting } from './TextV2'
import { UserCenterSetting } from './UserCenter'
import { VideoBlockSetting } from './Video'
import BlockViewSettings from './View'

export type BlockSettingsFormValue = BlockAbstract['config'] & Pick<BlockAbstract, 'title' | 'type'>

export interface BlockSettingsProps {
    loading?: boolean
    appId: string
    value: BlockAbstract
    node: NodeIdWithScope
    onChange?: (v: BlockAbstract) => void
    dataSourceList: DataSourceBase[]
    dataSource?: DataSourceAbstract
    allDataSources: DataSourceAbstract[]
    pointer: string
    allPages: PageAbstract[]
    allViews?: { title: string; dsId: string; id: string }[]
}

const getBlockConfigFromFormValue = (values: DeepPartial<BlockSettingsFormValue>) => {
    const { title, type, ...config } = values

    if (type === BlockType.text || type === BlockType.qrBarcode) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        const { content, ...rest } = config
        return { content: { value: content }, ...rest } as TextBlockConfig
    }

    return config
}
// inherit
export const BlockSettings = React.forwardRef<HTMLDivElement, BlockSettingsProps>(
    ({ loading, appId, value, node, pointer, onChange, dataSourceList, dataSource, allDataSources, allPages, allViews }, ref) => {
        const previewType = usePreviewType()
        // 获取外部package传入的数据源数据，在组件内部存储atom
        const updateDataSource = useSetAtom(dataSourceAtom)
        const updateDataSourceList = useSetAtom(dataSourceListIdsAtom)
        const updateApplicationInfo = useSetAtom(applicationAtom)
        const findUseObject = useFindUse(FindUseLocationType.BLOCK, { id: value.id })

        const { personOptions } = useApplicationContext()
        const latest = useLatest({ value, onChange, personOptions })
        const mergeValue = useMemo<BlockSettingsFormValue>(() => {
            const breakPoint = getBreakPointConfigure(previewType, value.config.breakPoint, value.config.breakPoints)
            return {
                ...value.config,
                breakPoint,
                content: value.type === BlockType.text || value.type === BlockType.qrBarcode ? value.config.content.value : undefined,
                title: value.title,
                type: value.type
            }
        }, [previewType, value.config, value.title, value.type])

        const mergeLatest = useLatest(mergeValue)
        const methods = useForm<BlockSettingsFormValue>({
            values: mergeValue
        })

        const handleSyncOne = useCallback(
            (name: string, previewType: ApplicationPreviewEnum) => {
                if (previewType === ApplicationPreviewEnum.desktop) {
                    return
                }
                const block = clone(latest.current.value)
                const mergeBreakPoint = mergeLatest.current.breakPoint
                const target = deepMerge(block.config.breakPoint, mergeBreakPoint, [name])
                const index = findIndex(item => item?.id === previewType, block.config.breakPoints)
                if (index >= 0) {
                    block.config.breakPoints[index].breakKeys = block.config.breakPoints[index].breakKeys?.filter(key => key !== name)
                }
                block.config.breakPoint = target
                latest.current.onChange?.(block)
            },
            [latest, mergeLatest]
        )

        const handleSyncAll = useCallback(
            (previewType: ApplicationPreviewEnum) => {
                if (previewType === ApplicationPreviewEnum.desktop) {
                    return
                }
                const block = clone(latest.current.value)
                block.config.breakPoint = mergeLatest.current.breakPoint
                const index = findIndex(item => item?.id === previewType, block.config.breakPoints)
                if (index >= 0) {
                    block.config.breakPoints[index].breakKeys = []
                }
                latest.current.onChange?.(block)
            },
            [latest, mergeLatest]
        )

        useEffect(() => {
            updateDataSource(dataSource)
            return () => {
                updateDataSource(undefined)
            }
        }, [updateDataSource, dataSource])

        useEffect(() => {
            updateDataSourceList(dataSourceList)
        }, [updateDataSourceList, dataSourceList, allDataSources, appId])

        useEffect(() => {
            updateApplicationInfo(draft => {
                draft.pageList = allPages
                draft.dataSourceList = allDataSources.filter(item => item.appId === appId)
                draft.viewList = allViews ?? []
            })
        }, [updateApplicationInfo, allPages, allDataSources, allViews, appId])

        const extraSetting = useMemo(() => {
            switch (value.type) {
                case BlockType.view: {
                    return value.config.viewType === 'custom' ? <CustomViewSetting id={value.id} /> : <BlockViewSettings id={value.id} />
                }
                case BlockType.chart: {
                    return <ChartBlockSettings />
                }
                case BlockType.iframe: {
                    return <IframeSetting />
                }
                case BlockType.field: {
                    return <BlockFieldSettings pointer={pointer} id={value.id} />
                }
                case BlockType.image: {
                    return <BlockImageSettings blockId={value.id} allPages={allPages} />
                }
                case BlockType.button: {
                    return <ButtonSetting allPages={allPages} />
                }
                case BlockType.card: {
                    return <CardBlockSetting blockId={value.id} allPages={allPages} />
                }
                case BlockType.divider: {
                    return <DividerBlockSetting />
                }
                case BlockType.video: {
                    return <VideoBlockSetting id={value.id} />
                }
                case BlockType.fieldGroup: {
                    return <FieldGroupSetting pointer={pointer} id={value.id} />
                }
                case BlockType.breadcrumb: {
                    return <BreadcrumbSetting />
                }
                case BlockType.collapse: {
                    return <CollapseSetting blockId={value.id} />
                }
                case BlockType.file: {
                    return <FileBlockSetting blockId={value.id} />
                }
                case BlockType.container:
                case BlockType.formContainer: {
                    return <ContainerSetting type={value.type} />
                }
                case BlockType.tabs: {
                    return <TabsSettings />
                }
                case BlockType.text: {
                    return <TextBlockSetting />
                }
                case BlockType.filter: {
                    return <FilterSetting blockId={value.id} />
                }
                case BlockType.qrBarcode: {
                    return <QrBarcodeSetting />
                }
                case BlockType.subForm: {
                    return <SubFormSetting blockId={value.id} />
                }
                case BlockType.icon: {
                    return <IconSetting />
                }
                case BlockType.userCenter: {
                    return <UserCenterSetting />
                }
                case BlockType.floatBox: {
                    return <FloatSetting />
                }
                case BlockType.onOff: {
                    return <OnOffSetting allPages={allPages} />
                }
                default: {
                    return null
                }
            }
        }, [allPages, pointer, value.config, value.id, value.type])

        useEffect(() => {
            const { unsubscribe } = methods.watch((formValue, info) => {
                if (fastEqual(deepOmitNil(mergeLatest.current), deepOmitNil(formValue))) {
                    return
                }

                const { title = '', type, breakPoint } = formValue
                const block = latest.current.value
                // const block = {config: mergeLatest.current}
                const blockConfig = { ...block.config, ...getBlockConfigFromFormValue(formValue) }
                const validBreakPoint = clone(breakPoint)
                if (previewType !== ApplicationPreviewEnum.desktop && info.name?.includes('breakPoint')) {
                    blockConfig.breakPoint = block.config.breakPoint
                    const newBreakPoint = mergeSingleBreakPointConfigure(
                        block.config.breakPoint,
                        validBreakPoint as Partial<BaseBreakPointConfigProtocol>
                    )
                    const index = findIndex(item => item?.id === previewType, blockConfig.breakPoints)
                    if (index >= 0) {
                        blockConfig.breakPoints[index] = {
                            ...newBreakPoint,
                            id: blockConfig.breakPoints[index]?.id,
                            name: blockConfig.breakPoints[index]?.name
                        }
                    }
                }

                const children = hasChildrenBlock(block) ? block.children : undefined
                const newBlockAbstract = {
                    title,
                    type,
                    config: blockConfig,
                    id: block.id,
                    children,
                    isMasterSynchronous: latest.current.value.isMasterSynchronous,
                    isLeafSynchronous: latest.current.value.isLeafSynchronous
                } as BlockAbstract
                latest.current.onChange?.(clone(newBlockAbstract))
            })
            return unsubscribe
        }, [methods, latest, previewType, value.config.breakPoint, mergeLatest])

        // 检查配置有效性
        useEffect(() => {
            if (!dataSource) {
                return
            }
            // 看板视图分组配置检验
            // const isKanbanView = value.type === 'view' && value.config.viewType === 'kanban'
            if (value.type === 'view' && value.config.viewType === 'kanban') {
                const { kanbanGroupConfigure } = value.config
                const { groupByFieldId, groupConfig } = kanbanGroupConfigure || {}
                const hasKanbanConfig = !!groupByFieldId && !!groupConfig
                if (hasKanbanConfig) {
                    const field = dataSource.schema[groupByFieldId]
                    if (!field) {
                        const init = {
                            groupByFieldId: '',
                            groupConfig: []
                        }
                        methods.setValue('kanbanGroupConfigure', init)
                        return
                    }
                    if (field.type === 'select') {
                        const options = new Set([...field.select.options.map(item => item.label), EMPTY_COLUMN_GROUP])
                        // 删除过期的选项
                        const newConfig = groupConfig.filter(item => options.has(item.value))

                        if (!fastEqual(groupConfig, newConfig)) {
                            const kanbanGroupConfigure: GroupConfigure = {
                                groupByFieldId,
                                groupConfig: newConfig
                            }
                            methods.setValue('kanbanGroupConfigure', kanbanGroupConfigure)
                        }
                    }
                    if (field.type === 'person') {
                        const isSystemPerson = systemPersonFieldId.has(field.id)
                        const staticAnonymous = isSystemPerson ? [...PERSON_ID] : [ANONYMOUS]

                        const options = new Set([
                            ...latest.current.personOptions.map(item => item.userId),
                            ...staticAnonymous,
                            EMPTY_COLUMN_GROUP
                        ])
                        const newConfig = groupConfig.filter(item => options.has(item.value))
                        if (!fastEqual(groupConfig, newConfig)) {
                            const kanbanGroupConfigure: GroupConfigure = {
                                groupByFieldId,
                                groupConfig: newConfig
                            }
                            methods.setValue('kanbanGroupConfigure', kanbanGroupConfigure)
                        }
                    }
                }
            }
        }, [dataSource, latest, methods, value.config, value.type])

        return (
            <SC.BlockSettingContainer ref={ref}>
                <FindUseObjectContextProvider value={findUseObject}>
                    <BlockSettingProvider blockId={value.id} scope={node.scope}>
                        <BreakPointProvider onSyncOne={handleSyncOne} onSyncAll={handleSyncAll}>
                            <SC.BlockSettingContentContainer>
                                <FormProvider {...methods}>{extraSetting}</FormProvider>
                            </SC.BlockSettingContentContainer>
                        </BreakPointProvider>
                    </BlockSettingProvider>
                </FindUseObjectContextProvider>
            </SC.BlockSettingContainer>
        )
    }
)
