import { Button } from '@lighthouse/bui'
import type { AnimationBreakPoint } from '@lighthouse/core'
import { AnimationProtocols, AnimationTypeEnum } from '@lighthouse/core'
import { useApplicationContext, useFillPickerContext } from '@lighthouse/shared'
import { mergeRefs, nanoid } from '@lighthouse/tools'
import type { AnimationOptions, AnimationPlaybackControls, UseInViewOptions } from 'motion/react'
import { AnimatePresence, motion, useAnimate, useAnimation, useInView } from 'motion/react'
import { findLast, mergeDeepRight } from 'rambda'
import type { RefObject } from 'react'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useAnimationContext, useContainerBlockContext } from '../../context'
import {
    getAnimation,
    getBuilderHoverAnimationConfig,
    getBuilderLayoutAnimationConfig,
    getDefaultAnimationConfig,
    getLayoutAnimationConfig,
    getParentScrollContainer,
    getTransition,
    getVisibleHeight
} from './utils'

interface AnimationDecoratorsProps extends React.ComponentPropsWithoutRef<'div'> {
    blockId: string
    className?: string
    animation?: AnimationBreakPoint
    children?: React.ReactNode
}

const useAnimationInView = (ref: RefObject<Element | null>, props: UseInViewOptions & { start: 'top' | 'bottom' | 'center' }) => {
    const { start, ...rest } = props
    const isInView = useInView(ref, rest)

    const [isIn, setIsIn] = useState<boolean | null>(null)

    const scrollHandlerRef = useRef((e: Event) => {
        if (start === 'top') {
            return
        }
        if (e.target instanceof HTMLElement && ref.current) {
            const rect = ref.current.getBoundingClientRect()
            const scrollRect = e.target.getBoundingClientRect()
            const visitHeight = getVisibleHeight(ref.current)
            if (start === 'center') {
                setIsIn(visitHeight >= rect.height / 2)
                return
            }
            if (start === 'bottom') {
                const isScrollBottom = rect.bottom < scrollRect.bottom
                setIsIn(visitHeight >= rect.height - 12 || isScrollBottom)
            }
        }
    })

    useEffect(() => {
        if (!ref.current) {
            return
        }
        const scrollContainer = getParentScrollContainer(ref.current)
        if (scrollContainer) {
            scrollContainer.addEventListener('scroll', scrollHandlerRef.current)
            scrollContainer.addEventListener('resize', scrollHandlerRef.current)
        }
    }, [ref])

    return isIn === null ? isInView : start === 'top' ? isInView : isIn && isInView
}

export const AnimationDecorators = React.forwardRef<HTMLDivElement, AnimationDecoratorsProps>(
    ({ blockId, className, animation, children, style: propsStyle, ...rest }, ref) => {
        // return <MotionDemo />
        const { palettes } = useFillPickerContext()
        const { appId, isBuilder } = useApplicationContext()
        const { scope } = useContainerBlockContext()
        const animationKeyRef = useRef('init')
        const onceRef = useRef(false)
        const animationControlRef = useRef<null | AnimationPlaybackControls>(null)
        const { queue, onAnimationComplete, onAnimationStart } = useAnimationContext()
        const {
            blockId: ctxBlockId,
            played: ctxPlayed,
            scope: ctxScope,
            type: ctxType
        } = useMemo(
            () =>
                findLast(v => v.blockId === blockId && v.scope === scope, queue) ?? {
                    blockId: ''
                },
            [blockId, queue, scope]
        )

        const containerRef = useRef<HTMLDivElement>(null)

        const disabled = blockId !== ctxBlockId || (!ctxPlayed && ctxScope !== scope)

        const { layerInView, loop, hover } = animation ?? {}
        const { replay, start = 'top', enter } = layerInView ?? {}
        const inView = useAnimationInView(containerRef, { start, once: !replay })

        const [animateRef, animate] = useAnimate()

        // 管理端预览
        const getAnimateBuilder = useCallback(
            (type: AnimationTypeEnum) => {
                const { layerInView, loop, hover } = animation ?? {}
                const { enter } = layerInView ?? {}
                // 应用端禁用
                if (!appId || !isBuilder) {
                    return
                }
                // 需要播放的动画blockId和scope匹配上时
                if (blockId === ctxBlockId && ctxScope === scope) {
                    switch (type) {
                        case AnimationTypeEnum.loop: {
                            if (!loop?.effect) {
                                return
                            }
                            const transition = getTransition(loop?.effect?.transition, {
                                repeat: Infinity,
                                repeatType: loop.effect.repeatType ?? 'loop'
                            })
                            return {
                                // key: animationKeyRef.current,
                                animate: getAnimation({ appId, animation: loop?.effect, palettes }),
                                transition: {
                                    ...transition,
                                    duration: loop?.effect?.transition.time,
                                    visualDuration: loop?.effect?.transition.time
                                }
                            }
                        }
                        case AnimationTypeEnum.hover: {
                            if (!hover?.effect) {
                                return
                            }
                            const transition = getTransition(hover?.effect?.transition)
                            return (
                                hover?.effect && {
                                    // key: animationKeyRef.current,
                                    animate: getBuilderHoverAnimationConfig(
                                        { appId, animation: hover?.effect, palettes },
                                        {
                                            styles: propsStyle
                                        }
                                    ),
                                    transition: {
                                        ...transition,
                                        duration: hover?.effect?.transition.time,
                                        visualDuration: hover?.effect?.transition.time
                                    }
                                }
                            )
                        }
                        default: {
                            if (!enter) {
                                return
                            }
                            return {
                                // 设置动画css起始值
                                key: animationKeyRef.current,
                                // style: getAnimationStyles({ appId, animation: enter, palettes }),
                                // initial: getAnimation({ appId, animation: enter, palettes }),
                                animate: getBuilderLayoutAnimationConfig({ appId, animation: enter, palettes }),
                                transition: getTransition(enter?.transition)
                            }
                        }
                    }
                }
            },
            [animation, appId, blockId, ctxBlockId, ctxScope, isBuilder, palettes, propsStyle, scope]
        )
        const onCannelBuilderAnimate = useCallback(() => {
            if (!appId || !isBuilder) {
                return
            }
            animationControlRef?.current && animationControlRef.current?.cancel?.()
        }, [appId, isBuilder])

        const onPlayBuilderAnimate = useCallback(
            (type?: AnimationTypeEnum) => {
                if (!appId || !isBuilder || !type) {
                    return
                }
                const animateBuilder = getAnimateBuilder(type)
                if (animateBuilder) {
                    animationControlRef?.current && animationControlRef.current.cancel?.()
                    animationKeyRef.current = nanoid()
                    onAnimationStart?.({ blockId, scope })
                    animationControlRef.current = animate(
                        animateRef.current,
                        animateBuilder.animate,
                        // @HACK 如果更新key引发对象更新，从而导致动效执行
                        {
                            key: animationKeyRef.current,
                            ...animateBuilder.transition
                        } as unknown as AnimationOptions
                    )
                    animationControlRef.current
                        .then(() => {
                            if (type !== 'loop') {
                                onAnimationComplete?.({ blockId, scope })
                            }
                        })
                        .catch(() => {
                            //
                        })
                }
            },
            [animate, animateRef, appId, blockId, getAnimateBuilder, isBuilder, onAnimationComplete, onAnimationStart, scope]
        )

        // 动画执行后style变化时更新样式
        useEffect(() => {
            if (animationKeyRef.current !== 'init') {
                animate(animateRef.current, propsStyle, {
                    duration: 0.1
                })
            }
        }, [animate, animateRef, propsStyle])

        // 管理端动画
        useEffect(() => {
            if (blockId === ctxBlockId && ctxScope === scope) {
                ctxPlayed ? onPlayBuilderAnimate(ctxType) : onCannelBuilderAnimate()
            }
        }, [blockId, ctxBlockId, ctxPlayed, ctxScope, ctxType, onCannelBuilderAnimate, onPlayBuilderAnimate, scope])

        // 应用端出现动画
        useEffect(() => {
            const isReplayAnimate = layerInView?.replay ? true : !onceRef.current
            if (!isBuilder && appId && layerInView?.enter && layerInView?.exit && isReplayAnimate) {
                onceRef.current = inView || onceRef.current
                inView
                    ? animate(
                          animateRef.current,
                          getLayoutAnimationConfig({ appId, animation: layerInView?.enter, palettes }),
                          getTransition(layerInView?.enter.transition) as AnimationOptions
                      )
                    : animate(
                          animateRef.current,
                          getAnimation({
                              appId,
                              animation: layerInView?.exit,
                              palettes
                          }),
                          getTransition(layerInView?.exit.transition) as AnimationOptions
                      )
            }
        }, [animate, animateRef, appId, blockId, enter, inView, isBuilder, layerInView?.enter, layerInView?.exit, layerInView?.replay, palettes])


        // 应用端循环动画
        useEffect(() => {
            if (!isBuilder && appId && loop?.effect) {
                animate(
                    animateRef.current,
                    getAnimation(
                        {
                            appId,
                            animation: loop?.effect,
                            palettes
                        },
                        {
                            diffAnimate: getDefaultAnimationConfig()
                        }
                    ),
                    getTransition(loop.effect.transition, {
                        repeat: Infinity,
                        repeatType: loop.effect.repeatType ?? 'loop'
                    }) as AnimationOptions
                )
            }
        }, [animate, animateRef, appId, isBuilder, loop?.effect, palettes])

        const animationRunner = useMemo(() => {
            // 管理端禁用原本动画
            if (isBuilder) {
                return {}
            }
            const { enter, exit } = layerInView ?? {}
            if (!appId) {
                return {}
            }

            return {
                initial:
                    enter &&
                    getAnimation({
                        appId,
                        animation: enter,
                        palettes
                    }),
                // animate: loop?.effect && {
                //     ...getAnimation({
                //         appId,
                //         animation: loop?.effect,
                //         palettes
                //     }),
                //     // rotate:360,
                //     transition: getTransition(loop?.effect?.transition, { repeat: Infinity, repeatType: loop.effect.repeatType ?? 'loop' })
                // },
                whileHover: hover?.effect && {
                    ...getAnimation(
                        {
                            appId,
                            animation: hover?.effect,
                            palettes
                        },
                        {
                            diffAnimate: getDefaultAnimationConfig()
                        }
                    ),
                    // scale:1.5,
                    transition: getTransition(hover?.effect?.transition)
                }
                // whileInView: enter && {
                //     ...getDefaultAnimationConfig(),
                //     transition: getTransition(enter?.transition)
                // },
                // enter: enter && {
                //     ...getDefaultAnimationConfig(),
                //     transition: getTransition(enter?.transition)
                // },
                // exit: exit && {
                //     ...getDefaultAnimationConfig(),
                //     transition: getTransition(exit?.transition)
                // }
            }
        }, [appId, hover?.effect, isBuilder, layerInView, palettes])

        const finallyStyle = propsStyle

        return (
            <AnimatePresence>
                <motion.div
                    ref={mergeRefs([ref, containerRef, animateRef])}
                    onAnimationComplete={() => {
                        onAnimationComplete?.({ blockId, scope })
                    }}
                    onAnimationStart={() => {
                        onAnimationStart?.({ blockId, scope })
                    }}
                    disabled={disabled}
                    className={className}
                    // {...animateBuilder}
                    {...animationRunner}
                    {...rest}
                    style={finallyStyle}
                >
                    {children}
                </motion.div>
            </AnimatePresence>
        )
    }
)

const a = {
    x: [0, 0, 0],
    y: [0, 0, 0],
    rotate: 0,
    rotateX: 0,
    rotateY: 0,
    rotateZ: 0,
    scale: [1, 1.1, 1],
    skewX: [0, 0, 0],
    skewY: [0, 0, 0],
    opacity: [1, 1, 1],
    background: null,
    boxShadow: ['unset', '0px 0px 0px 0px #ffffff', 'unset'],
    backgroundColor: null,
    backgroundImage: ['linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0))', 'linear-gradient(90deg, #1cc984 0%, #F00000 100%)']
}

export const MotionDemo = () => {
    const [animateRef, animate] = useAnimate()
    const animationControlRef = useRef<null | AnimationPlaybackControls>(null)
    const [key, setKey] = useState('')

    useEffect(() => {
        animationControlRef.current = animate(animateRef.current, a, {
            duration: 2,
            key
            // ...animateBuilder.transition,
        } as AnimationOptions)
    }, [animate, animateRef, key])

    const [style, setStyle] = useState({})

    const resetStyle = () => {
        setStyle({}) // 清空样式
    }

    // useEffect(() => {
    //     key && animationControlRef?.current?.play()
    // }, [key])

    return (
        <>
            <motion.div
                // key={key}
                // animate={}
                transition={{
                    duration: 2,
                    key
                }}
                ref={animateRef}
                style={{
                    width: 100,
                    height: 100,
                    backgroundColor: 'coral',
                    boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.9)',
                    ...style
                }}
            />
            <Button
                onClick={() => {
                    animationControlRef.current?.stop()
                    // animationControlRef.current?.play()
                    // setKey(nanoid())
                    resetStyle()
                    // setAnimation({ x: [0, 150, 0] })
                }}
            >
                刷新motion
            </Button>
        </>
    )
}
